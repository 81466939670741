import React from 'react';
import UserContext from '../providers/UserContext';

function AccountNotif() {
  const [ isVisible, setVisibility ] = React.useState(true);
  const [{ isSignedIn }] = React.useContext(UserContext);

  const handleClick = () => {
    setVisibility(false);
  }

  return (
    <React.Fragment>
      { isVisible && isSignedIn ? (
        <div className="app-banner full-width">
          <div className="app-banner-responsive">
            <span className="app-banner-text">
              Accounts older than <b>30 minutes</b> may be deleted.
            </span>
            <button className="btn btn--app-banner" onClick={handleClick}>
              Hide
            </button>
          </div>
        </div>
      ) : null }
    </React.Fragment>
  )
}

export default AccountNotif;
