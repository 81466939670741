import React from 'react';
import IVSContext from './IVSContext';

function getQueryStringValue (key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

const IVSProvider = ({ children }) => {
    const value = {
        channelArn: window.atob("YXJuOmF3czppdnM6dXMtd2VzdC0yOjI5ODA4MzU3MzYzMjpjaGFubmVsL25mMXRpN2xWa1l6Yw=="),
        playbackUrl: window.atob("aHR0cHM6Ly80ZGE0YTIyMDI2ZDMudXMtd2VzdC0yLnBsYXliYWNrLmxpdmUtdmlkZW8ubmV0L2FwaS92aWRlby92MS91cy13ZXN0LTIuMjk4MDgzNTczNjMyLmNoYW5uZWwubmYxdGk3bFZrWXpjLm0zdTg="),
    };

    return (
        <IVSContext.Provider value={value}>
            {children}
        </IVSContext.Provider>
    );
};

export default IVSProvider
